import React, { useEffect, useState, useContext, Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/langContext"

import { Button } from "../components/buttons/buttons"
import { Card } from "../components/card/card"
import { Section, Container } from "../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const FIND_BY_KEYWORD_V2 = gql`
  query Search(
    $key: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $language: LanguageCodeFilterEnum
  ) {
    contentNodes(
      where: {
        search: $key
        contentTypes: [POST, PROJECT]
        language: $language
      }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          __typename
          ... on Project {
            title
            excerpt
            slug
            propertyTypes {
              nodes {
                name
              }
            }
            locations {
              nodes {
                name
                slug
              }
            }
            translation(language: ID) {
              title
              excerpt
              slug
              propertyTypes {
                nodes {
                  name
                }
              }
            }
          }
          ... on Post {
            id
            title
            slug
            excerpt
            translation(language: ID) {
              id
              title
              slug
              excerpt
            }
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        total
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

const FIND_PROPERTY = gql`
  query Finder(
    $facilityId: [ID]
    $locationId: [ID]
    $typeId: [ID]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    projects(
      where: {
        facilityIn: $facilityId
        locationIn: $locationId
        propertyTypeIn: $typeId
      }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          databaseId
          title
          slug
          excerpt
          propertyTypes {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            caption
          }
        }
      }
      pageInfo {
        total
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
`

const GET_STATIC_FACILITIES = graphql`
  query Facilities {
    wordPress {
      facilities(first: 100) {
        nodes {
          name
          slug
          facilityId
          projects {
            nodes {
              propertyTypes {
                nodes {
                  slug
                  name
                }
              }
            }
          }
        }
      }
      locations(first: 20) {
        nodes {
          locationId
          slug
          name
        }
      }
      propertyTypes(first: 200) {
        nodes {
          name
          slug
          propertyTypeId
          children {
            nodes {
              slug
              name
              projects(first: 1000) {
                nodes {
                  title
                  slug
                  propertyTypes(first: 200) {
                    nodes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
      categories {
        nodes {
          name
          posts(first: 1000) {
            nodes {
              slug
            }
          }
        }
      }
      page(id: "career", idType: URI) {
        title
        slug
        excerpt
      }
    }
  }
`

const changeParentSlug = (slug) => {
  switch(slug) {
    case "kavling-doubledream-property":
      slug = "kavling";
      break;
    case "residential-doubledream-property":
      slug = "residential";
      break;
  }
  return slug
}

const changeParentSlugSmartMove = (slug) => {
  switch(slug) {
    case "smartmove-shophouse":
      slug = "shophouse";
      break;
    case "smartmove-residential":
      slug = "residential";
      break;
    case "smartmove-kavling":
      slug = "kavling";
      break;
    case "smartmove-apartment":
      slug = "apartment";
      break;
  }
  return slug
}

const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
  if (hasNextPage || hasPreviousPage) {
    return (
      <div className="d-flex justify-content-end">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={!hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={!hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  } else {
    return <div className="pagination"></div>
  }
}

export const SearchResult = ({ location }) => {
  const offset = 0 
  const perPage = 10
  const [total, setTotal] = useState(null)
  const [details, setDetails] = useState({
    facilities: [],
    locations: [],
    propertyTypeId: null,
  })
  const { lang } = useContext(LangContext)
  const [findResult, setFindResult] = useState({
    resultValues: [],
  })
  const [pagination, setPagination] = useState({
    offset,
    perPage
  })
  const [filteredResults, setFilteredResults] = useState([])

  const data = useStaticQuery(GET_STATIC_FACILITIES)
  //prettier-ignore
  const [ getProperty, { data: properties, loading: loadingProperties }] = useLazyQuery(FIND_PROPERTY)
  //prettier-ignore
  const [getResult, { data: searchResult, loading }] = useLazyQuery(FIND_BY_KEYWORD_V2)

  const listFacilities = data.wordPress.facilities.nodes
  const listLocations = data.wordPress.locations.nodes
  const listPropertyTypes = data.wordPress.propertyTypes.nodes
  const listPosts = data.wordPress.categories.nodes

  const propertyFinderResult = properties && properties.projects.edges
  const result = searchResult && searchResult.contentNodes.edges

  let defaultPropertyTypesId = []

  listPropertyTypes.map(item =>
    defaultPropertyTypesId.push(item.propertyTypeId)
  )

  useEffect(() => {
    if (
      location.search
        .toLowerCase()
        .split("?key=")
        .join("") == "career" ||
      location.search
        .toLowerCase()
        .split("?key=")
        .join("")
        .replace(/\+/g, " ")
        .includes("career")
    ) {
      let resultValuesTemp = findResult.resultValues
      result &&
        result.map(item => {
          if (item.node.slug == "career") {
            resultValuesTemp.push({
              typeName: item.node.__typename,
              label: item.node.slug,
              title: item.node.title,
              description: item.node.excerpt,
              linkTo: item.node.slug,
            })
            resultValuesTemp.splice(resultValuesTemp.indexOf("career"), 0)
            setFindResult({ ...findResult, resultValues: resultValuesTemp })
          }
        })
    }
  }, [result])

  useEffect(() => {
    if (
      location.search
        .toLowerCase()
        .split("?key=")
        .join("") == "job" ||
      location.search
        .toLowerCase()
        .split("?key=")
        .join("")
        .replace(/\+/g, " ")
        .includes("job")
    ) {
      let resultValuesTemp = findResult.resultValues
      resultValuesTemp.push({
        typeName: "Page",
        label: data.wordPress.page.slug,
        title: data.wordPress.page.title,
        description: data.wordPress.page.excerpt,
        linkTo: data.wordPress.page.slug,
      })
      resultValuesTemp.splice(resultValuesTemp.indexOf("career"), 0)
      setFindResult({ ...findResult, resultValues: resultValuesTemp })
    }
  }, [])

  useEffect(() => {
    if (location.search.split("?key=").length > 1) {
      getResult({
        variables: {
          key:
            location.search.split("?key=").length > 1
              ? location.search
                  .toLowerCase()
                  .split("?key=")
                  .join("")
                  .replace(/\+/g, " ")
                  .includes("career")
                ? "career"
                : location.search.split("?key=").join("")
              : "...",
          language: "EN",
          first: 100,
          last: 0,
          after: "",
          before: "",
        },
      })
    } else if (location.search.split("?key=").length === 1) {
      let facilities = []
      let locations = []
      let propertyTypeId =
        (location.state &&
          location.state.propertyType &&
          listPropertyTypes.find(x => x.name === location.state.propertyType)
            .propertyTypeId) ||
        null

      // location.state &&
      //   stateValues.bathroom !== undefined &&
      //   listFacilities.map(
      //     item =>
      //       item.name.toLowerCase() === stateValues.bathroom.label &&
      //       facilities.push(item.facilityId)
      //   )
      // location.state &&
      //   stateValues.bedroom !== undefined &&
      //   listFacilities.map(
      //     item =>
      //       item.name.toLowerCase() === stateValues.bedroom.label &&
      //       facilities.push(item.facilityId)
      //   )
      location.state &&
        location.state.values &&
        listLocations.map(
          item =>
            item.name === location.state.values.location.label &&
            locations.push(item.locationId)
        )
      // eslint-disable-next-line
      location.state &&
        location.state.values &&
        location.state.values.residenceFacilities?.map(item => {
          listFacilities.map(facility => {
            if (facility.slug === item) {
              facilities.push(facility.facilityId)
            }
          })
        })

      // location.state &&
      //   stateValues.employee !== undefined &&
      //   listFacilities.map(
      //     item =>
      //       item.name.toLowerCase() === stateValues.employee.label &&
      //       facilities.push(item.facilityId)
      //   )
      // location.state &&
      //   stateValues.officeFacilities !== undefined &&
      //   stateValues.officeFacilities.map(item => {
      //     listFacilities.map(facility => {
      //       if (facility.slug === item) {
      //         facilities.push(facility.facilityId)
      //       }
      //       return -1
      //     })
      //     return 1
      //   })
      // location.state &&
      //   stateValues.size !== undefined &&
      //   listFacilities.map(
      //     item =>
      //       item.name.toLowerCase() === stateValues.size.label &&
      //       facilities.push(item.facilityId)
      //   )

      // location.state &&
      //   stateValues.facilities !== undefined &&
      //   stateValues.facilities.map(item => {
      //     listFacilities.map(facility => {
      //       if (facility.slug === item) {
      //         facilities.push(facility.facilityId)
      //       }
      //     })
      //   })

      getProperty({
        variables: {
          facilityId: facilities.length > 0 ? facilities : null,
          locationId: locations.length > 0 ? locations : null,
          typeId: propertyTypeId ? [propertyTypeId] : defaultPropertyTypesId,
          first: 10,
        },
      })

      setDetails({
        facilities: facilities,
        locations: locations,
        propertyTypeId: propertyTypeId,
      })
    }
  }, [lang])

  useEffect(() => {
    if (searchResult && filteredResults && filteredResults.length != 0) {
      if (total === null) {
        if (
          location.search
            .toLowerCase()
            .split("?key=")
            .join("") == "job" ||
          location.search
            .toLowerCase()
            .split("?key=")
            .join("")
            .replace(/\+/g, " ")
            .includes("job")
        ) {
          setTotal(filteredResults.length + 1)
        } else {
          setTotal(filteredResults.length)
        }
      }
    } else if (properties) {
      if (total === null) {
        setTotal(properties.projects.pageInfo.total)
      }
    }
  }, [searchResult, properties, filteredResults, lang])

  useEffect(() => {
    if(searchResult) {
      const newResults = []
      for (const item  of searchResult?.contentNodes?.edges) {
        const node = item?.node
        if(node?.__typename === "Project") {
          // recreate(multiple) node if project has multiple property types
          const types = node?.propertyTypes?.nodes
          if(types.length > 1) {
            for (const type of types) {
              // clone current item and replace current 'propertyTypes' property
              const newItem = {...item, node: {
                ...item?.node,
                propertyTypes: {
                  nodes: [type]
                }
              }}

              // update newResults
              newResults.push(newItem)
            }
          } else {
            newResults.push(item)
          }
        } else {
          newResults.push(item)
        }
      }
      setFilteredResults(newResults)
    }
  }, [searchResult])

  // go to the top every time the page changes
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [pagination])

  const hasPreviousPage = () => {
    if (searchResult) {
      if(pagination.offset > offset) {
        return true
      }
      // return searchResult.contentNodes.pageInfo.hasPreviousPage
    } else if (properties) {
      return properties.projects.pageInfo.hasPreviousPage
    } else {
      return false
    }
  }

  const hasNextPage = () => {
    if (searchResult) {
      if(pagination.perPage < total) {
        return true
      }
      // return searchResult.contentNodes.pageInfo.hasNextPage
    } else if (properties) {
      return properties.projects.pageInfo.hasNextPage
    } else {
      return false
    }
  }

  const prevCursor = () => {
    if (searchResult) {
      return searchResult.contentNodes.pageInfo.startCursor
    } else if (properties) {
      return properties.projects.pageInfo.startCursor
    } else {
      return ""
    }
  }

  const nextCursor = () => {
    if (searchResult) {
      return searchResult.contentNodes.pageInfo.endCursor
    } else if (properties) {
      return properties.projects.pageInfo.endCursor
    } else {
      return ""
    }
  }

  const nextPage = cursor => {
    if (searchResult) {
      setPagination({
        ...pagination,
        offset: pagination.perPage, 
        perPage: pagination.perPage + perPage 
      })
      // getResult({
      //   variables: {
      //     key:
      //       location.search.split("?key=").length > 1
      //         ? location.search.split("?key=").join("")
      //         : "...",
      //     first: 10,
      //     after: `${cursor}`,
      //   },
      // })
    } else if (properties) {
      getProperty({
        variables: {
          facilityId: details.facilities.length > 0 ? details.facilities : null,
          locationId: details.locations.length > 0 ? details.locations : null,
          typeId: details.propertyTypeId
            ? [details.propertyTypeId]
            : defaultPropertyTypesId,
          first: 10,
          after: `${cursor}`,
        },
      })
    }
  }

  const previousPage = cursor => {
    if (searchResult) {
      setPagination({ 
        ...pagination, 
        offset: pagination.offset - perPage,
        perPage: pagination.perPage - perPage 
      })
      // getResult({
      //   variables: {
      //     key:
      //       location.search.split("?key=").length > 1
      //         ? location.search.split("?key=").join("")
      //         : "...",
      //     last: 10,
      //     before: `${cursor}`,
      //   },
      // })
    } else if (properties) {
      getProperty({
        variables: {
          facilityId: details.facilities.length > 0 ? details.facilities : null,
          locationId: details.locations.length > 0 ? details.locations : null,
          typeId: details.propertyTypeId
            ? [details.propertyTypeId]
            : defaultPropertyTypesId,
          last: 10,
          before: `${cursor}`,
        },
      })
    }
  }

  return (
    <Layout location={location}>
      <SEO title="Search Result" />

      <Section className="search-result bg-light">
        <Container className="mw-lg">
          {/* <HeadingBasic h2="Search" /> */}
          {total && total !== 0 ? (
            <p className="mb-0 text-muted">{total} results found</p>
          ) : null}
          <form 
            action={`/search-result`}
            className="mb-4">
            <input
              type="text"
              className="form-control form-control-search px-0"
              name="key"
              placeholder="Search"
            />
          </form>
          {findResult.resultValues.length > 0 &&
            findResult.resultValues.map((item, i) => {
              if (item.typeName === "Page") {
                return (
                  <Fragment key={i}>
                    <Card
                      forwardKey={`post-${i}`}
                      label={item.label || null}
                      labelClassName="mb-2 caption font-size-sm"
                      title={item.title || null}
                      titleClassName="h4 text-truncate-twoline"
                      text={item.description || null}
                      textClassName="text-truncate-twoline"
                      link={`/${item.linkTo || null}`}
                      className="card-search mb-3"
                    >
                      <Button variant="link" className="mb-n2">
                        Read more
                      </Button>
                    </Card>
                  </Fragment>
                )
              }
            })}
          {filteredResults &&
            filteredResults.slice(pagination.offset, pagination.perPage).map((item, i) => {
              if (item.node.__typename === "Post") {
                let validator = false
                listPosts.map(category => {
                  if (
                    category.posts.nodes.find(x => x.slug === item.node.slug)
                  ) {
                    validator = true
                  }
                })

                if (validator) {
                  return (
                    <Fragment key={i}>
                      <Card
                        forwardKey={`post-${i}`}
                        label="News"
                        labelClassName="mb-2 caption font-size-sm"
                        title={
                          lang === "EN"
                            ? item?.node?.title
                            : item?.node?.translation?.title ||
                              item?.node?.title
                        }
                        titleClassName="h4 text-truncate-twoline"
                        text={
                          lang === "EN"
                            ? item?.node?.excerpt
                            : item?.node?.translation?.excerpt ||
                              item?.node?.excerpt
                        }
                        textClassName="text-truncate-twoline"
                        link={`/news/${item.node.slug}`}
                        className="card-search mb-3"
                      >
                        <Button variant="link" className="mb-n2">
                          {lang === "EN" ? "Read more" : "Baca selengkapnya"}
                        </Button>
                      </Card>
                    </Fragment>
                  )
                }
              } else if (item.node.__typename === "Project") {
                let thisSubPropertyType = ""
                let thisSubPropertyTypeSmartMove = ""
                let slugofPropertyTypeInfiniteLiving = ""
                if (item.node.propertyTypes.nodes[0].name.toLowerCase() === "doubledream property") {
                  listPropertyTypes.forEach(thisItem => {
                    if(thisItem.slug === "doubledream-property"){
                      thisItem.children.nodes.forEach(thisChildren => {
                        thisChildren.projects.nodes.forEach(thisProject => {
                          if(thisProject.slug === item.node.slug){
                            thisSubPropertyType = changeParentSlug(thisChildren.slug)
                          }
                        })
                      })
                    }
                  })
                }else if (item.node.propertyTypes.nodes[0].name.toLowerCase() === "smartmove property") {
                  listPropertyTypes.forEach(thisItem => {
                    if(thisItem.slug === "smartmove-property"){
                      thisItem.children.nodes.forEach(thisChildren => {
                        thisChildren.projects.nodes.forEach(thisProject => {
                          if(thisProject.slug === item.node.slug){
                            thisSubPropertyTypeSmartMove = changeParentSlugSmartMove(thisChildren.slug)
                          }
                        })
                      })
                    }
                  })
                }else if (item.node.propertyTypes.nodes[0].name.toLowerCase() === "infinite living") {
                  listPropertyTypes.forEach(thisItem => {
                    if(thisItem.slug === "infiniteliving"){
                      thisItem.children.nodes.forEach(thisChildren => {
                        thisChildren.projects.nodes.forEach(thisProject => {
                          if(thisProject.slug === item.node.slug){
                            thisProject?.propertyTypes?.nodes.forEach(propertyInfinite => {
                              if (propertyInfinite.slug !== "infiniteliving") {
                                slugofPropertyTypeInfiniteLiving = propertyInfinite.slug
                              }
                            })
                          }
                        })
                      })
                    }
                  })
                }
                return (
                  <Fragment key={i}>
                    <Card
                      forwardKey={`post-${i}`}
                      label={
                        item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                          "properti" ||
                        item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                          "kavling"
                          ? "Wish For Home"
                          : item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                            "doubledream property"
                            ? "Double Dream"
                            : item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                              "smartmove property"
                              ? "Smart Move"
                              : item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                                "infinite living"
                                ? "Infinite Living"
                                : "Development"
                      }
                      labelClassName="mb-2 caption font-size-sm"
                      title={
                        lang === "EN"
                          ? item?.node?.title
                          : item?.node?.translation?.title || item?.node?.title
                      }
                      titleClassName="h4 text-truncate-twoline"
                      link={
                        item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                        "properti"
                          ? `/wishforhome/${item?.node?.locations?.nodes[0]?.slug}/${item?.node?.slug}`
                          : item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "kavling"
                          ? `/wishforhome/${item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase()}/${
                              item?.node?.locations?.nodes[0]?.slug
                            }/${item?.node?.slug}`
                          : item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "doubledream property"
                          ? `/smartmove/${thisSubPropertyType}/${item?.node?.locations?.nodes[0]?.slug}/${
                            item?.node?.slug
                            }`
                          : item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "smartmove property"
                          ? `/smartmove/${thisSubPropertyTypeSmartMove}/${item?.node?.locations?.nodes[0]?.slug}/${
                            item?.node?.slug
                            }`
                          : item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "infinite living"
                          ? `/development/${slugofPropertyTypeInfiniteLiving}/${
                            item?.node?.slug
                            }`
                          : `/development/${item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase()}/${
                              item?.node?.slug
                            }`
                      }
                      className="card-search mb-3"
                    >
                      <ReactMarkdown
                        source={
                          lang === "EN"
                            ? item?.node?.excerpt
                            : item?.node?.translation?.excerpt ||
                              item?.node?.excerpt
                        }
                        escapeHtml={false}
                        className="text-truncate-twoline text-muted"
                      />
                      <Button variant="link" className="mb-n2">
                        {lang === "EN" ? "Read more" : "Baca selengkapnya"}
                      </Button>
                    </Card>
                  </Fragment>
                )
              }
            })}
          {!loading && filteredResults && filteredResults.length === 0 && "Results not found"}
          {propertyFinderResult &&
            propertyFinderResult.map((item, i) => {
              return (
                <Card
                  forwardKey={`post-${i}`}
                  label="Development"
                  labelClassName="mb-2 caption font-size-sm"
                  title={
                    lang === "EN"
                      ? item?.node?.title
                      : item?.node?.translation?.title || item?.node?.title
                  }
                  titleClassName="h4 text-truncate-twoline"
                  link={`/development/${item.node.propertyTypes.nodes[0].name.toLowerCase()}/${
                    item.node.slug
                  }`}
                  className="card-search mb-3"
                >
                  <ReactMarkdown
                    source={
                      lang === "EN"
                        ? item?.node?.excerpt
                        : item?.node?.translation?.excerpt ||
                          item?.node?.excerpt
                    }
                    escapeHtml={false}
                    className="text-truncate-twoline text-muted"
                  />
                  <Button variant="link" className="mb-n2">
                    {lang === "EN" ? "Read more" : "Baca selengkapnya"}
                  </Button>
                </Card>
              )
            })}
          {!loadingProperties &&
            properties !== undefined &&
            propertyFinderResult.length === 0 &&
            "Results not found"}
          <Pagination
            hasPreviousPage={hasPreviousPage()}
            hasNextPage={hasNextPage()}
            prevPage={() => previousPage(prevCursor())}
            nextPage={() => nextPage(nextCursor())}
          />
          {(loadingProperties || loading) && (
            <div className="text-center loader-lg">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default SearchResult
